// Source:
// https://web-crunch.com/posts/rails-drag-drop-active-storage-stimulus-dropzone

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function fetchTurboStream(path, options = {}) {

  options.headers = {
    Accept: "text/vnd.turbo-stream.html"
  }

  if (options["method"] == "post") {
    options.headers["X-CSRF-Token"] = getMetaValue("csrf-token")
  }

  fetch(path, options)
  .then(r => {
    if(r.ok) {
      r.text().then(html => Turbo.renderStreamMessage(html))
    } else {
    }
  })

}
