import { Application } from "@hotwired/stimulus"
import * as ActiveStorage from "@rails/activestorage"
import Lightbox from "stimulus-lightbox"
import Dropdown from 'stimulus-dropdown'
import ReadMore from 'stimulus-read-more'
import Reveal from 'stimulus-reveal-controller'
import { Autocomplete } from 'stimulus-autocomplete'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Clipboard from 'stimulus-clipboard'
import ZoomImageController from '@kanety/stimulus-zoom-image';

const application = Application.start()

ActiveStorage.start()

// Adapted to fix issue:
//   https://github.com/stimulus-components/stimulus-textarea-autogrow/issues/5
let improvedAutogrow = class extends TextareaAutogrow {
  connect() {
    super.connect()

    if(this.element.scrollHeight == 0) { // element is initially hidden
      this.element.style.height = `${(this.linesCount() * this.lineHeight() * 2) + this.getPaddingsSum()}px`
    }
  }

  getStyle(el, styleProp) {
    let y = null
    if (el.currentStyle)
      y = el.currentStyle[styleProp]
    else if (window.getComputedStyle)
      y = document.defaultView.getComputedStyle(el,null).getPropertyValue(styleProp)
    return y
  }

  linesCount() {
    return this.element.value.split("\n").length || this.element.rows
  }

  lineHeight() {
    let height_px = this.getStyle(this.element, 'line-height')
    return parseInt(height_px) || 0
  }

  getPaddingsSum() {
    let sum = 0
    let padding_top_px = this.getStyle(this.element, 'padding-top')
    if(padding_top_px) {
      sum += parseInt(padding_top_px) || 0
    }
    let padding_bottom_px = this.getStyle(this.element, 'padding-bottom')
    if(padding_bottom_px) {
      sum += parseInt(padding_bottom_px) || 0
    }
    return sum
  }
}

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

application.register('lightbox', Lightbox)
application.register('dropdown', Dropdown)
application.register('read-more', ReadMore)
application.register('reveal', Reveal)
application.register('autocomplete', Autocomplete)
application.register('textarea-autogrow', improvedAutogrow)
application.register('checkbox-select-all', CheckboxSelectAll)
application.register('clipboard', Clipboard)
application.register('zoom-image', ZoomImageController);

export { application }
