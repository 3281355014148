// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./channels"
import "./stream_animations"
import "trix"
import "@rails/actiontext"
import { fetchTurboStream } from "./helpers"

import TC from "@rolemodel/turbo-confirm"
TC.start({ activeClass: "daisyui-modal-open" })

window.fetchTurboStream = fetchTurboStream
window.workingOpenUrl = function(url) { window.open(url) }
